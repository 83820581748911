<template>
  <div>
    <v-container>
      <cmnWhomList :baseCrudKey="getProgramDetails.baseCrudKey"/>
    </v-container>
  </div>
</template>

<script>
import cmnWhomList from '../../components/common/whom/cmnWhomList.vue';
import { getProgramDetailsByParams } from '../../components/common/whom/cmnWhom.util';

export default {
  name: 'cmnWhom',
  components: {
    cmnWhomList,
  },
  data: () => ({
  }),
  created() {
  },
  computed: {
    getProgramDetails() {
      const programDetails = getProgramDetailsByParams(this);
      return programDetails;
    },
  },
  methods: {
  },
};

</script>
