<template>
  <div>
    <v-container >
      <CRUDList
        :canAdd="true"
        :canEdit="true"
        :canSave="true"
        :canDelete="true"
        :baseCrudKey="baseCrudKey"
        :headers="headers"
        :items="whomItems"
        :createNewItem="newItemDialog"
        :defaultSortBy="sortBy"
        toolbarTitle="Received Of-List"
        @loadCRUDList="handleLoadCRUDList"
        @upsertItem="upsertItem"
        @emitDeleteRowItem="deleteItem"
      >
      <template v-slot:[`additionalTools`]="{}">
        <v-col cols="2">
          <v-text-field class="receivedOf fiscalYear"
            label="Fiscal Year"
            v-model.number="criteriaState.fiscalYear"
            type="number"
            ref="fiscalYear"
            @input="criteriaChanged"
          ></v-text-field>
        </v-col>
        <v-divider
          class="mx-4" inset vertical
        ></v-divider>
        <v-col cols="2">
          <v-text-field
            type="number"
            label="Merge to FY"
            v-model.number="copyToFY"
            ></v-text-field>
            </v-col>
            <v-divider
            class="mx-4" inset vertical
          ></v-divider>
            <v-btn
            class="mx-2"
            @click="copyAll"
            text color="primary">
            Merge
          </v-btn>
          <v-divider
            class="mx-4" inset vertical
          ></v-divider>
          <v-btn
            class="mx-2"
            @click="clearAll"
            text color="primary">
            Delete all for FY
          </v-btn>
        </template>
        <template v-slot:[`editedItem`]="{}">
          <cmnWhomEditedItem :baseCrudKey="baseCrudKey"/>
        </template>
      </CRUDList>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import CRUDList from '../base/crud/CRUDList.vue';
import cmnWhomEditedItem from './cmnWhomEditedItem.vue';
import {
  toFiscalYear,
} from '../../../util/shared/tmc-global';
import { checkStringEmptiness } from '../../../util/shared/vue-global';

const fields = {
  _id: '',
  description: '',
  fiscalYear: '',
};

const getHeaders = (canDelete) => {
  const headers = [
    { text: 'Description', value: 'description' },
  ];
  if (canDelete) {
    headers.push({ text: 'Actions', value: 'actions', sortable: false });
  }
  return headers;
};

export default {
  name: 'cmnWhomList',
  components: {
    CRUDList,
    cmnWhomEditedItem,
  },
  data: () => ({
    headers: [],
    copyToFY: undefined,
    sortBy: [
      {
        column: 'description',
        direction: 'asc',
      },
    ],
  }),
  props: {
    baseCrudKey: {
      type: String,
      default: 'cmnWhomList',
    },
  },
  created() {
    this.initCriteria();
    this.headers = getHeaders(this.canDelete);
    this.copyToFY = toFiscalYear(new Date(this.todaysDate));
  },
  computed: {
    ...mapGetters('base/crud', [
      'criteria',
      'item',
      'items',
    ]),
    ...mapGetters(['todaysDate']),
    criteriaState: {
      get() {
        return this.criteria(this.baseCrudKey);
      },
      set(value) {
        this.setCriteria([this.baseCrudKey, value]);
      },
    },
    editedItem: {
      get() {
        return this.item(this.baseCrudKey);
      },
      set(value) {
        this.setItem([this.baseCrudKey, value]);
      },
    },
    whomItems() {
      return this.items(this.baseCrudKey);
    },
  },
  methods: {
    ...mapActions('common/cmnWhom', [
      'loadCmnWhom',
      'upsrtCmnWhom',
      'delCmnWhom',
    ]),
    ...mapMutations('base/crud', [
      'setCriteria',
    ]),

    initCriteria() {
      // reset criteria while merge flag is true
      this.criteriaState = {};
      this.criteriaState.fiscalYear = toFiscalYear(new Date(this.todaysDate));
    },
    handleLoadCRUDList() {
      const { baseCrudKey } = this;
      const criteria = this.criteriaState;
      this.loadCmnWhom({
        criteria,
        fields,
        baseCrudKey,
      });
    },
    upsertItem(item) {
      const { baseCrudKey } = this;
      this.upsrtCmnWhom({ item, fields, baseCrudKey });
    },
    deleteItem(item) {
      if (window.confirm('Are you sure you want to delete this item?')) {
        const { baseCrudKey } = this;
        this.delCmnWhom({ item, baseCrudKey });
      }
    },
    criteriaChanged() {
      const { baseCrudKey } = this;
      const criteria = this.criteriaState;
      this.loadCmnWhom({
        criteria,
        fields,
        baseCrudKey,
      });
    },
    newItemDialog() {
      const newItem = {};
      if (!checkStringEmptiness(this.criteriaState.fiscalYear)) {
        newItem.fiscalYear = this.criteriaState.fiscalYear;
      }
      return newItem;
    },
    async copyAll() {
      if (!window.confirm(`This will create any entries from FY
        ${this.criteriaState.fiscalYear} in FY ${this.copyToFY}. Continue?`)) {
        return;
      }
      const copyPromises = this.whomItems
        .map((w) => {
          const newWhom = { ...w };
          delete newWhom._id;
          newWhom.fiscalYear = this.copyToFY;
          return newWhom;
        })
        .map((w) => {
          const copyPromise = this.upsrtCmnWhom({
            item: w,
            baseCrudKey: this.baseCrudKey,
            fields,
          });
          return copyPromise;
        });
      await Promise.all(copyPromises);
      this.loadCmnWhom({ criteria: this.criteriaState, fields, baseCrudKey: this.baseCrudKey });
    },
    async clearAll() {
      if (!window.confirm(`This will DELETE all entries from FY ${this.criteriaState.fiscalYear}. Continue?`)) {
        return;
      }
      const deletePromises = this.whomItems.map((w) => {
        const delPromise = this.delCmnWhom({
          item: w,
          baseCrudKey: this.baseCrudKey,
          fields,
        });
        return delPromise;
      });
      await Promise.all(deletePromises);
      this.loadCmnWhom({ criteria: this.criteriaState, fields, baseCrudKey: this.baseCrudKey });
    },
  },
};
</script>
