<template>
  <v-container>
    <v-row>
      <v-col cols="3">
        <v-text-field
          :autofocus="true"
          v-model="editedItem.description"
          label= "Description"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          label= "Fiscal Year"
          readonly
          v-model="editedItem.fiscalYear"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  mapGetters,
  mapMutations,
} from 'vuex';

export default {
  name: 'cmnWhomEditedItem',
  components: {
  },
  props: {
    baseCrudKey: {
      type: String,
      default: 'cmnWhomDialog',
    },
  },
  computed: {
    ...mapGetters('base/crud', [
      'item',
    ]),
    editedItem: {
      get() {
        return this.item(this.baseCrudKey);
      },
      set(value) {
        this.setItem([this.baseCrudKey, value]);
      },
    },
  },
  methods: {
    ...mapMutations('base/crud', [
      'setItem',
    ]),
  },
};
</script>
